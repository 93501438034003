import React, { type FunctionComponent } from "react";
import PrimaryLogoSvg from "./PrimaryLogo.svg";
import { observer } from "mobx-react-lite";

const PrimaryLogo: FunctionComponent<
  React.ImgHTMLAttributes<HTMLImageElement>
> = observer(function PrimaryLogo(props) {
  return <img src={PrimaryLogoSvg as unknown as string} {...props} />;
});

export default PrimaryLogo;

import { action, makeAutoObservable } from "mobx";
import { type User, getCurrentUser } from "~/api/CurrentUser";

export default class AppStore {
  loaded: boolean = false;
  currentUser: User | null = null;
  trueUser: User | null = null;
  impersonated: boolean | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  load(): void {
    getCurrentUser()
      .then(
        action("fetchCurrentUser", (currentUser) => {
          this.currentUser = currentUser.currentUser;
          this.trueUser = currentUser.trueUser;
          this.impersonated = currentUser.impersonated;

          this.loaded = true;
        })
      )
      .catch(() => {});
  }
}

import { type FlashMessage } from "~/stores/FlashStore";

interface ParsedFlashObject {
  alert?: string;
  notice?: string;
}

export default class FlashParser {
  flashJson: string;

  constructor(flashJson: string) {
    this.flashJson = flashJson;
  }

  call(): FlashMessage[] {
    const flashes: FlashMessage[] = [];

    try {
      const parsedflashObject: ParsedFlashObject = JSON.parse(this.flashJson);

      if (parsedflashObject.alert != null)
        flashes.push({ type: "error", message: parsedflashObject.alert });
      if (parsedflashObject.notice != null)
        flashes.push({ type: "info", message: parsedflashObject.notice });
    } catch (_error) {}

    return flashes;
  }
}

import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";

const AuthenticatedAxios = applyCaseMiddleware(
  axios.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
);

AuthenticatedAxios.interceptors.request.use((config) => {
  const csrfToken = document
    .getElementsByName("csrf-token")[0]
    .getAttribute("content");
  config.headers["X-CSRF-Token"] = csrfToken;

  return config;
});

export default AuthenticatedAxios;

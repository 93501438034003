import { Alert, AlertIcon, Stack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useContext, type FunctionComponent } from "react";
import FlashContext from "~/contexts/FlashContext";
import { type FlashType } from "~/stores/FlashStore";

interface FlashAlertProps {
  type: FlashType;
  message: string;
}

const FlashAlert: FunctionComponent<FlashAlertProps> = observer(
  function FlashAlert({ type, message }) {
    if (message == null || message === "") return;
    if (type == null) type = "info";

    return (
      <Alert status={type} borderRadius="8px">
        <AlertIcon />
        {message}
      </Alert>
    );
  }
);

const FlashAlerts: FunctionComponent = observer(function FlashAlerts() {
  const flashStore = useContext(FlashContext);

  const flashAlerts = flashStore.currentFlashes.map((flashMessage) => (
    <FlashAlert
      key={flashMessage.message}
      message={flashMessage.message}
      type={flashMessage.type}
    />
  ));

  return (
    <Stack spacing={3} w="100%">
      {flashAlerts}
    </Stack>
  );
});

export default FlashAlerts;

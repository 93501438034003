import React, {
  type ReactNode,
  useEffect,
  type FunctionComponent,
} from "react";
import { observer } from "mobx-react-lite";
import AppStore from "~/stores/AppStore";
import { BrowserRouter, useLocation } from "react-router-dom";
import AppContext from "~/contexts/AppContext";
import FlashContext from "~/contexts/FlashContext";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "~/theme";
import type FlashStore from "~/stores/FlashStore";

interface ApplicationProvidersProps {
  appStore?: AppStore;
  flashStore: FlashStore;
  authenticated: boolean;
  children: ReactNode;
}

const ApplicationProviders: FunctionComponent<ApplicationProvidersProps> =
  observer(function ApplicationProviders({ appStore, flashStore, children }) {
    const location = useLocation();

    useEffect(() => {
      flashStore.updateKey(location.key);
    }, [location.key]);

    useEffect(() => {
      appStore?.load();
    }, [appStore]);

    let component = (
      <FlashContext.Provider value={flashStore}>
        <ChakraProvider theme={theme}>{children}</ChakraProvider>
      </FlashContext.Provider>
    );

    if (appStore !== undefined) {
      component = (
        <AppContext.Provider value={appStore}>{component}</AppContext.Provider>
      );
    }

    return component;
  });

interface ApplicationProps {
  authenticated: boolean;
  flashStore: FlashStore;
  children: ReactNode;
}

const Application: FunctionComponent<ApplicationProps> = observer(
  function Application({ authenticated, children, flashStore }) {
    const appStore = authenticated ? new AppStore() : undefined;

    return (
      <BrowserRouter>
        <ApplicationProviders
          appStore={appStore}
          flashStore={flashStore}
          authenticated={false}
        >
          {children}
        </ApplicationProviders>
      </BrowserRouter>
    );
  }
);

export default Application;

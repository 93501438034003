import { makeAutoObservable } from "mobx";

export type FlashType = "error" | "info" | "success" | "warning";

export interface FlashMessage {
  type: FlashType;
  message: string;
}

export default class FlashStore {
  nextFlashes: FlashMessage[];
  currKey: string = "default";
  keyToFlashes: Map<string, FlashMessage[]>;

  constructor(initialFlashes: FlashMessage[]) {
    this.nextFlashes = initialFlashes;
    this.keyToFlashes = new Map();
    this.keyToFlashes.set(undefined as unknown as string, this.nextFlashes);

    makeAutoObservable(this);
  }

  addFlashToCurr(flash: FlashMessage): void {
    this.currentFlashes.push(flash);
  }

  addFlashToNext(flash: FlashMessage): void {
    this.nextFlashes.push(flash);
  }

  get currentFlashes(): FlashMessage[] {
    return this.keyToFlashes.get(this.currKey) ?? [];
  }

  updateKey(key: string): void {
    if (this.keyToFlashes.has(key)) return;

    this.currKey = key;
    this.keyToFlashes.set(key, this.nextFlashes);
    this.nextFlashes = [];
  }
}

import { StepsTheme as Steps } from "chakra-ui-steps";
import { MultiSelectTheme } from "chakra-multiselect";
import { CalendarDefaultTheme } from "@uselessdev/datepicker";

const components = {
  MultiSelect: MultiSelectTheme,
  ...CalendarDefaultTheme.components,
  Steps,
};

export default components;

import { extendTheme } from "@chakra-ui/react";
import fonts from "./fonts";
import colors from "./colors";
import radii from "./radii";
import components from "./components";

const theme = extendTheme({
  fonts,
  colors,
  radii,
  components,
});

export default theme;
